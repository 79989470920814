import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { config } from "./config";
import "./index.css";
import App from "./App";
import Home from "./Components/Home";
import reportWebVitals from "./reportWebVitals";
import "@arcgis/core/assets/esri/themes/light/main.css";

const AppRouted = () => (
  <Router>
    <Routes>
      <Route path="/antarctica" element={<App config={config.antarctica} />} />
      <Route path="/greenland" element={<App config={config.greenland} />} />
      <Route path="/" element={<Home />} />
    </Routes>
  </Router>
);

const rootElement = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <AppRouted />
  </React.StrictMode>,
  rootElement
);
reportWebVitals();
