import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div class="home">
      <Link to="/antarctica">Antarctica</Link> |{" "}
      <Link to="/greenland">Greenland</Link>
    </div>
  );
};
export default Home;
