export const config = {
  greenland: {
    basemapUrl:
      "https://services.arcgisonline.com/arcgis/rest/services/Polar/Arctic_Imagery/MapServer",
    sampleUrl:
      "https://iced-samples.apps.pgc.umn.edu/api/v2/greenland/sample?size=100000",
    sitesUrl: "https://iced-samples.apps.pgc.umn.edu/api/v2/greenland/sites",
    coresUrl: "https://iced-samples-dev.apps.pgc.umn.edu/api/v2/greenland/core",
    highResUrl:
      "https://web.overlord.pgc.umn.edu/arcgis/rest/services/fridge/md_pgc_comm_opt_mono_mosaic_pan_arc/ImageServer",
    extraLayers: [
      {
        url: "https://elevation2.arcgis.com/arcgis/rest/services/Polar/ArcticDEM/ImageServer/",
        title: "ArcticDEM Hillshade",
        type: "ImageServer",
        zIndex: 3,
        attribution: "Polar Geospatial Center",
        renderingRule: {
          rasterFunction: "Hillshade Gray",
          rasterFunctionArguments: { ZFactor_201637_125527_313: 0.5 },
        },
      },
    ],
    extent: {
      xmin: 2160513,
      ymin: 1235998,
      xmax: 5742676,
      ymax: 4083070,
      spatialReference: {
        wkid: 5936,
      },
    },
    rotation: 110,
    title: "Greenland",
    titleColor: "#666666",
    imgPath: "/greenland.png",
    allSites: "https://version2.ice-d.org/greenland/sites",
    allPubs: "https://version2.ice-d.org/greenland/publications",
    sampleBaseURL: "https://version2.ice-d.org/greenland/sample/",
    siteBaseURL: "https://version2.ice-d.org/greenland/site/",
    coreBaseURL: "https://version2.ice-d.org/greenland/core/",
  },
  antarctica: {
    basemapUrl:
      "https://overlord.pgc.umn.edu/arcgis/rest/services/imagery/ant_pgc_composite_mosaic/MapServer",
    sampleUrl:
      "https://iced-samples.apps.pgc.umn.edu/api/v2/antarctica/sample?size=100000",
    sitesUrl: "https://iced-samples.apps.pgc.umn.edu/api/v2/antarctica/sites",
    coresUrl:
      "https://iced-samples-dev.apps.pgc.umn.edu/api/v2/antarctica/core",
    highResUrl:
      "https://web.overlord.pgc.umn.edu/arcgis/rest/services/fridge/md_pgc_comm_opt_mono_mosaic_pan_ant/ImageServer",
    extraLayers: [
      {
        url: "https://elevation2.arcgis.com/arcgis/rest/services/Polar/AntarcticDEM/ImageServer/",
        title: "REMA Hillshade",
        type: "ImageServer",
        zIndex: 3,
        attribution: "Polar Geospatial Center",
        renderingRule: {
          rasterFunction: "Hillshade Gray",
          rasterFunctionArguments: { ZFactor_2018610_6519_829: 0.5 },
        },
      },
      {
        url: "https://overlord.pgc.umn.edu/arcgis/services/maps/ant_usgs_250k_topos/MapServer/WMSServer",
        title: "USGS 1:250,000 Topos",
        type: "WMSServer",
        zIndex: 2,
        attribution: "USGS",
      },
      {
        url: "https://overlord.pgc.umn.edu/arcgis/services/reference/ant_add_rck_pg/MapServer/WMSServer",
        title: "ADD Rock Polygons",
        type: "WMSServer",
        zIndex: 3,
        attribution: "British Antarctic Survey",
      },
    ],
    extent: {
      xmin: -3655999,
      ymin: -2355997,
      xmax: 4680000,
      ymax: 2644002,
      spatialReference: {
        wkid: 3031,
      },
    },
    rotation: 0,
    title: "Antarctica",
    titleColor: "#666666",
    imgPath: "/antarctica.jpg",
    allSites: "https://version2.ice-d.org/antarctica/sites",
    allPubs: "https://version2.ice-d.org/antarctica/publications",
    sampleBaseURL: "https://version2.ice-d.org/antarctica/sample/",
    siteBaseURL: "https://version2.ice-d.org/antarctica/site/",
    coreBaseURL: "https://version2.ice-d.org/antarctica/core/",
  },
};
