import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import TileLayer from "@arcgis/core/layers/TileLayer";
import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import WMSLayer from "@arcgis/core/layers/WMSLayer";
import Extent from "@arcgis/core/geometry/Extent";
import ScaleBar from "@arcgis/core/widgets/ScaleBar";
import Home from "@arcgis/core/widgets/Home";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import LayerList from "@arcgis/core/widgets/LayerList";
import Fullscreen from "@arcgis/core/widgets/Fullscreen";
import TileInfo from "@arcgis/core/layers/support/TileInfo";
import React, { useEffect, useRef } from "react";
import esriConfig from "@arcgis/core/config";
import { useKeycloak } from "@react-keycloak/web";
import ReactDOMServer from "react-dom/server";
import Cookies from "js-cookie";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ColorHash from "color-hash";

//Idea: look into filtering features based on props using FeatureEffect
//https://developers.arcgis.com/javascript/latest/api-reference/esri-layers-support-FeatureEffect.html

import SamplePopup from "./SamplePopup";

const highResTitle = "0.5m PGC Commercial Imagery";
const samplesTitle = "ICE-D Samples (high zoom)";
const sitesTitle = "ICE-D Sites (low zoom)";
const coresTitle = "ICE-D Cores";
const colorHash = new ColorHash();

const isLatitude = (num) =>
  num !== null && num !== undefined && isFinite(num) && Math.abs(num) <= 90;
const isLongitude = (num) =>
  num !== null && num !== undefined && isFinite(num) && Math.abs(num) <= 180;
const isZoom = (num) =>
  num !== null && num !== undefined && isFinite(num) && Math.abs(num) <= 22;

const getWMSLayers = (layer) => {
  return new WMSLayer({
    url: layer.url,
    visible: false,
    id: layer.title,
    title: layer.title,
    copyright: layer.attribution,
  });
};

function MapComponent(props) {
  const {
    basemapUrl,
    sampleUrl,
    sitesUrl,
    coresUrl,
    highResUrl,
    extent,
    rotation,
    extraLayers,
  } = props.config;
  const { keycloak } = useKeycloak({});

  let map = new Map({});
  const mapDiv = useRef(null);

  const buildPopup = (feature) => {
    return ReactDOMServer.renderToStaticMarkup(
      <SamplePopup
        attributes={feature.graphic.attributes}
        sampleBaseURL={props.config.sampleBaseURL}
        siteBaseURL={props.config.siteBaseURL}
      />
    );
  };

  const getGeoJsonBlob = async (geoJsonUrl) => {
    const response = await fetch(geoJsonUrl);
    if (!response.ok) throw new Error();
    const json = await response.json();
    const blob = new Blob([JSON.stringify(json.items)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    return url;
  };

  const getCores = async (coresUrl, epsg) => {
    const url = await getGeoJsonBlob(coresUrl);

    const buildCorePopupContent = (feature) => {
      const title = feature.graphic.attributes.name;
      return ReactDOMServer.renderToStaticMarkup(
        <div className="popup-styled">
          <a
            title="opens in new window"
            className="popup-button"
            href={props.config.coreBaseURL + title}
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <b>{title}</b>
              <div>
                View Core<b></b>
              </div>
            </div>
          </a>
        </div>
      );
    };

    const geoJSONLayer = new GeoJSONLayer({
      title: coresTitle,
      id: "icedSites",
      url: url,
      copyright:
        "ICE-D - The Informal Cosmogenic-Nuclide Exposure-Age Database",
      spatialReference: {
        wkid: epsg,
      },
      opacity: 0.8,
      renderer: {
        type: "simple", // autocasts as new SimpleRenderer()
        symbol: {
          type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
          color: "#263238",
          style: "triangle",
          outline: {
            color: "#ae9ced",
            width: "2px",
          },
        },
      },
      popupTemplate: {
        title: "Cored sites",
        content: buildCorePopupContent,
        outFields: ["*"],
        overwriteActions: true,
        actions: [
          {
            title: "Zoom to",
            id: "zoom-to-samples",
            className: "esri-icon-zoom-to-object",
          },
        ],
      },
    });
    return geoJSONLayer;
  };

  const getSites = async (sitesURL, epsg) => {
    const url = await getGeoJsonBlob(sitesURL);

    const buildSitePopupContent = (feature) => {
      const samples = feature.graphic.attributes.number_of_samples;
      return samples > 1
        ? samples + " samples at this site"
        : samples + " sample at this site";
    };

    const geoJSONLayer = new GeoJSONLayer({
      title: sitesTitle,
      id: "icedSites",
      // labelingInfo: [labelClass],
      url: url,
      copyright:
        "ICE-D - The Informal Cosmogenic-Nuclide Exposure-Age Database",
      spatialReference: {
        wkid: epsg,
      },
      maxScale: 1000000,
      opacity: 0.75,
      orderBy: [{ field: "number_of_samples" }],
      renderer: {
        type: "simple", // autocasts as new SimpleRenderer()
        symbol: {
          type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
          // size: 10,
          color: "#4c36db",
          outline: {
            color: "#ae9ced",
            width: "1px",
            // opacity: 1,
          },
        },
        visualVariables: [
          {
            type: "size",
            field: "number_of_samples",
            legendOptions: { title: "Number of samples" },
            stops: [
              {
                value: 1,
                size: 6,
              },
              {
                value: 30,
                size: 20,
              },
            ],
          },
        ],
      },
      popupTemplate: {
        title: "{site}",
        content: buildSitePopupContent,
        outFields: ["*"],
        overwriteActions: true,
        actions: [
          {
            title: "Zoom to",
            id: "zoom-to-samples",
            className: "esri-icon-zoom-to-object",
          },
        ],
      },
    });
    return geoJSONLayer;
  };

  const getSamples = async (samplesUrl, epsg) => {
    const response = await fetch(samplesUrl);
    const samples = await response.json();
    const sites = [
      ...new Set(
        samples.items.features.map((sample) => sample.properties.site)
      ),
    ];
    let uniqueSitesValues = [];
    for (let site of sites) {
      const color = colorHash.hex(site);
      uniqueSitesValues.push({
        value: site,
        label: site,
        symbol: {
          type: "simple-marker",
          size: 12,
          color,
          style: "diamond",
          outline: {
            color: "#8bf6ff",
            width: 1,
          },
        },
      });
    }
    const blob = new Blob([JSON.stringify(samples.items)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const geoJSONLayer = new GeoJSONLayer({
      title: samplesTitle,
      id: "icedSamples",
      url: url,
      copyright:
        "ICE-D - The Informal Cosmogenic-Nuclide Exposure-Age Database",
      spatialReference: {
        wkid: epsg,
      },
      minScale: 1000000,
      opacity: 0.85,
      renderer: {
        type: "unique-value",
        field: "site",
        uniqueValueInfos: uniqueSitesValues,
      },
      popupTemplate: {
        overwriteActions: true,
        content: buildPopup,
        outFields: ["*"],
      },
    });
    return geoJSONLayer;
  };

  const initMap = async () => {
    const recentExtent = Cookies.get("extent");
    let basemap = new TileLayer({
      url: basemapUrl,
      title: "Basemap",
    });

    const view = new MapView({
      map: map,
      container: mapDiv.current,
      extent: new Extent(
        recentExtent !== undefined ? JSON.parse(recentExtent) : extent
      ),
      rotation: rotation,
      constraints: { maxScale: 0, lods: TileInfo.create().lods },
      highlightOptions: {
        color: [255, 255, 0, 1],
        haloOpacity: 1,
        fillOpacity: 1,
        shadowOpacity: 1,
      },
      popup: {
        dockEnabled: true,
        dockOptions: {
          position: "bottom-left",
          breakpoint: { width: 100000, height: 100000 }, //doesn't auto enable unless breakpoint condition satisfied...,
        },
      },
    });

    map.layers.add(basemap);

    if (extraLayers.length) {
      for (let layer of extraLayers) {
        if (layer.type === "WMSServer") {
          map.layers.add(getWMSLayers(layer), layer.zIndex);
        } else if (layer.type === "ImageServer") {
          const ImageryServiceLayer = new ImageryLayer({
            url: layer.url,
            title: layer.title,
            copyright: layer.attribution,
            renderingRule: layer.renderingRule ? layer.renderingRule : null,
            visible: false,
          });
          map.layers.add(ImageryServiceLayer, layer.zIndex);
        }
      }
    }

    const layerList = new LayerList({
      view: view,
      container: "layersContainer",
      listItemCreatedFunction: function (event) {
        const item = event.item;
        if (item.title === highResTitle) {
          item.actionsSections = [
            [
              {
                title: "Log out",
                className: "esri-icon-sign-out",
                id: "log-out",
              },
            ],
          ];
        }
        if (item.title === sitesTitle || item.title === coresTitle) {
          item.panel = {
            content: "legend",
            open: false,
          };
        }
      },
    });
    layerList.on("trigger-action", (event) => {
      if (event.action.id === "log-out") {
        logOut();
      }
    });
    view.ui.add(layerList, {
      position: "top-right",
    });

    const fullscreen = new Fullscreen({
      view: view,
    });
    view.ui.add(fullscreen, "bottom-right");

    const scaleBar = new ScaleBar({
      view: view,
      unit: "dual",
    });
    view.ui.add(scaleBar, {
      position: "bottom-right",
    });

    view.popup.on("trigger-action", async (event) => {
      if (event.action.id === "zoom-to-samples") {
        const samples = map.findLayerById("icedSamples");
        samples.visible = true;
        const query = samples.createQuery();
        query.where =
          "site = '" + view.popup.selectedFeature.attributes.site + "'";
        const results = await samples.queryFeatures(query);
        view.goTo({
          center: view.popup.selectedFeature.geometry,
          scale: 100000,
        });
        view.popup.open({
          features: results.features,
        });
      }
    });

    let homeWidget = new Home({
      view: view,
    });
    view.ui.add(homeWidget, "top-left");

    watchUtils.whenTrue(view, "stationary", function () {
      //store the map location so that it's the same when users navigate back after log in.
      if (view.center) {
        const newRecentExtent = JSON.stringify({
          spatialReference: { wkid: extent.spatialReference.wkid },
          xmin: view.extent.xmin,
          ymin: view.extent.ymin,
          xmax: view.extent.xmax,
          ymax: view.extent.ymax,
        });
        let date = new Date();
        date.setTime(date.getTime() + 60 * 1000); //60 seconds from now
        Cookies.set("extent", newRecentExtent, {
          expires: date,
          path: window.location.pathname,
        });
      }
    });
    view.when(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const lat = parseFloat(urlParams.get("y"));
      const lng = parseFloat(urlParams.get("x"));
      const zoom = parseFloat(urlParams.get("z"));
      if (isLatitude(lat) && isLongitude(lng) && isZoom(zoom)) {
        view.goTo({
          center: [lng, lat],
          zoom: zoom,
        });
      }
    });
  };

  const getDataLayers = async () => {
    Promise.allSettled([
      getSites(sitesUrl, extent.spatialReference.wkid),
      getSamples(sampleUrl, extent.spatialReference.wkid),
      getCores(coresUrl, extent.spatialReference.wkid),
    ]).then((results) => {
      const successfulLayers = [];
      results.forEach((result) => {
        if (result.status === "fulfilled") {
          successfulLayers.push(result.value);
        }
      });
      map.addMany(successfulLayers);
    });
  };

  const setupAuthParams = () => {
    esriConfig.request.interceptors.push({
      before: (params) => {
        if (params.url.toLowerCase().startsWith("https://web.overlord")) {
          params.requestOptions.headers = params.headers || {};
          params.requestOptions.headers.Authorization =
            "Bearer " + keycloak.token;
        }
      },
    });
  };

  const logIn = () => {
    confirmAlert({
      title: "Sign in with PGC",
      message:
        "If you do not have an account with the Polar Geospatial Center, you may request an account below. Please note: this account needs to be approved and you must provide proof of active U.S. federal funding for polar work.",
      buttons: [
        {
          label: "Log in",
          onClick: () => keycloak.login(),
        },
        {
          label: "Request Account",
          onClick: () =>
            window.open("https://users.pgc.umn.edu/request", "_blank"),
        },
        {
          label: "Cancel",
          onClick: null,
        },
      ],
    });
  };

  const logOut = () => {
    confirmAlert({
      title: "Log out of PGC account?",
      buttons: [
        {
          label: "Log out",
          onClick: () => keycloak.logout(),
        },
        {
          label: "Cancel",
          onClick: null,
        },
      ],
    });
  };

  useEffect(() => {
    if (mapDiv.current) {
      initMap();
      getDataLayers();

      keycloak.onReady = (auth) => {
        if (auth) {
          setupAuthParams();
          const ImageryServiceLayer = new ImageryLayer({
            url: highResUrl,
            title: highResTitle,
            copyright:
              "© Maxar " +
              new Date().getFullYear() +
              ", <a href='https://www.pgc.umn.edu/' target='_blank'>Polar Geospatial Center</a>",
          });
          map.layers.add(ImageryServiceLayer, 1);
          keycloak.onTokenExpired = async () => {
            try {
              await keycloak.updateToken(-1);
              setupAuthParams();
            } catch (e) {
              console.log("token refresh failed:" + e);
              logIn();
            }
          };
        }
      };
    }
  }, []);

  return (
    <div className="map-container">
      <div id="layersContainer">
        {!keycloak.authenticated && (
          <div onClick={() => logIn()}>
            <li className="esri-layer-list__item highRes">
              <div className="esri-layer-list__item-container">
                <div className="esri-layer-list__item-label">
                  <span className="esri-layer-list__item-toggle">
                    <span className="esri-icon-locked"></span>
                  </span>
                  <span
                    title="Log in"
                    className="not-logged-in esri-layer-list__item-title "
                  >
                    0.5m PGC Imagery <small>(click to log in)</small>
                  </span>
                </div>
              </div>
            </li>
          </div>
        )}
      </div>
      <div className="mapDiv" ref={mapDiv}></div>
    </div>
  );
}

export default MapComponent;
