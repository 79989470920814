function SamplePopup(props) {
  const { sample_name, site, what, lithology } = props.attributes;
  const { sampleBaseURL, siteBaseURL } = props;
  return (
    <div className="popup-styled">
      <a
        title="opens in new window"
        className="popup-button"
        href={sampleBaseURL + sample_name}
        target="_blank"
        rel="noreferrer"
      >
        <div>
          <b>{sample_name}</b>
          <div>
            View Sample<b></b>
          </div>
        </div>
      </a>
      <a
        title="opens in new window"
        className="popup-button"
        href={siteBaseURL + site}
        target="_blank"
        rel="noreferrer"
      >
        <div>
          <b>{site}</b>
          <div>View Site</div>
        </div>
      </a>
      <div className="popup-row">
        {what !== null && what.length !== 0 && (
          <div className="popup-attribute">
            What: <b>{what}</b>
          </div>
        )}
      </div>
      <div className="popup-row">
        {lithology !== null && lithology.length !== 0 && (
          <div className="popup-attribute">
            Lithology: <b>{lithology}</b>
          </div>
        )}
      </div>
    </div>
  );
}

export default SamplePopup;
