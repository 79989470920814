import "./App.css";
import MapComponent from "./Components/Map/Map";
import { useEffect } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";

function App(props) {
  const { config } = props;

  useEffect(() => {
    document.title = "ICE-D: " + config.title.toUpperCase();
  }, [config.title]);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "check-sso",
        //check login iframe to true once Danny updates to new keycloak (prevents reload flash on initial load)
        checkLoginIframe: false,
        promiseType: "native",
      }}
    >
      <div className="iced">
        <div className="header">
          <img alt={"Map image of " + config.title} src={config.imgPath}></img>
          <div className="header-title">ICE-D</div>
          <div className="header-subtitle">
            informal
            <br />
            cosmogenic-nuclide
            <br />
            exposure-age
            <br />
            database
          </div>
          <div className="header-title" style={{ color: config.titleColor }}>
            {config.title}
          </div>
        </div>
      </div>
      <hr></hr>

      <MapComponent config={config}></MapComponent>

      <hr></hr>
      <div className="about">
        <div className="about-section">
          <div className="about-header">About the Samples:</div>
          <div className="about-text">
            <p>
              The interactive map shows locations of samples collected in{" "}
              {config.title} for purposes of cosmogenic-nuclide exposure dating,
              from the ICE-D {config.title} database.
            </p>
            <p>Two important notes about the sample locations:</p>
            <div className="indent">
              <p>
                1) Many sample locations are drawn from published papers and not
                from direct field measurements. Imprecision, rounding, and
                publication or transcription errors are likely. If you have
                specific knowledge of sample location errors,{" "}
                <a href="mailto:balcs@bgc.org">contact Greg Balco</a>.
              </p>
              2) In areas of steep terrain, orthorectification of
              high-resolution imagery may not precisely coregister imagery and
              sample locations.
            </div>
          </div>
        </div>
        <div className="about-section">
          <div className="about-header">Other browse interfaces:</div>
          <div className="about-text">
            <p>
              <a href={config.allSites} target="_blank" rel="noreferrer">
                Browse by site
              </a>
              <br />
              <a href={config.allPubs} target="_blank" rel="noreferrer">
                Browse by publication
              </a>
            </p>
          </div>

          <div className="about-section">
            <div className="about-header">Contact:</div>
            <div className="about-text">
              Questions about this page: Greg Balco (
              <a href="mailto:balcs@bgc.org">balcs@bgc.org</a>)
              <br />
              Report map issues: Polar Geospatial Center (
              <a href="mailto:pgc-support@umn.edu">pgc-support@umn.edu</a>)
            </div>
          </div>
        </div>
      </div>
    </ReactKeycloakProvider>
  );
}

export default App;
